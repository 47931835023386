export default {
  search: [
    { name: '名称', type: 'text', fieldName: 'keyword', value: '' },
    { name: '联系人名字/电话', type: 'text', fieldName: 'contact_keyword', value: '' },
    {
      name: '行业',
      type: 'select',
      fieldName: 'industry_id',
      value: '',
      remote: true,
      props: {
        url: '/agent/industry/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '区域',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 2 },
      },
    },
  ],
  form: [
    { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
    // {
    //   fieldName: 'settlement_type',
    //   type: 'select',
    //   name: '结算方式',
    //   required: true,
    //   value: '',
    //   options: [
    //     { label: '预付', value: 1 },
    //     { label: '现结', value: 2 },
    //   ],
    // },
    {
      fieldName: 'status',
      type: 'select',
      name: '状态',
      required: true,
      value: '',
      options: [
        { label: '启用', value: 0 },
        { label: '禁用', value: 1 },
      ],
    },
    { fieldName: 'stop_num', type: 'number', name: '暂停线百分比', value: '', required: true },
    { fieldName: 'warning', type: 'text', name: '预警线(逗号隔开)', value: '', required: false, width: '55%' },
    {
      fieldName: 'is_open',
      type: 'select',
      name: '预付功能',
      required: true,
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: '所在城市',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id||contact_area_id',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '55%',
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 3 },
        checkStrictly: true,
      },
    },
    { fieldName: 'contact_address', type: 'text', name: '详细地址', value: '', required: false, width: '45%' },
    {
      name: '行业',
      type: 'select',
      fieldName: 'industry_id',
      value: '',
      remote: true,
      required: true,
      props: {
        url: '/agent/industry/list',
        value: 'id',
        label: 'name',
      },
    },
    { fieldName: 'contact_tel', type: 'text', name: '400号码', value: '', required: false },
    { fieldName: 'contact_wechat', type: 'text', name: '微信号', value: '', required: false },
    {
      fieldName: 'is_api',
      type: 'select',
      name: '是否开放接口',
      required: true,
      value: '',
      options: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],
    },
    {
      name: 'app_key',
      type: 'textarea',
      fieldName: 'app_key',
      value: '',
      disabled: true,
      width: '100%',
    },
    {
      name: 'app_secret',
      type: 'textarea',
      fieldName: 'app_secret',
      value: '',
      disabled: true,
      width: '100%',
    },
    {
      fieldName: 'bg_id',
      type: 'upload',
      name: '背景图',
      value: [],
      multiple: false,
      required: false,
      width: '100%',
    },
    { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
    {
      fieldName: 'attachment_ids',
      responseName: 'attachment',
      type: 'upload',
      name: '附件',
      value: [],
      multiple: true,
      required: false,
      width: '100%',
    },
    { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
  ],
};
