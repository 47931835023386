<template>
  <lz-card
    class="mt-20"
    title="关联客户">
    <lz-list
      :config="config"
      @table-btn-click="handleTableBtnClick"
      :table="table"
      :is-router="false"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-card>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      config: {
        url: '/agent/company/list',
        setTableFormat: (response) => {
          response.forEach((element) => {
            this.$set(element, 'htmlButton', [{
              name: '详情',
              key: 'view',
              type: 'success',
            }]);
          });
          return response;
        },
      },
      search: [
        { name: '代理商id', fieldName: 'agent_id', value: '' },
      ],
      table: [
        { name: '名称', prop: 'name' },
        { name: '销售渠道', prop: 'sale_type_name' },
        { name: '企业类型', prop: 'business' },
        { name: '行业', prop: 'industry' },
        { name: '区域',
          func: (ops) => `${ops.contact_province_name} ${ops.contact_city_name} ${ops.contact_area_name}`,
        },
        { name: '操作', prop: 'htmlButton', width: '90px' },
      ],
    };
  },
  methods: {
    init(id) {
      this.search[0].value = id;
      this.$nextTick(() => {
        this.$refs.lzList.getData(true);
      });
    },
    handleTableBtnClick(type, element) {
      const query = {
        keyword: element.name,
      };
      const { href } = this.$router.resolve({
        name: 'company-list',
        query,
      });
      window.open(href);
    },
  },
};
</script>
