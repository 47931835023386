<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      table-height="97%"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
      <contact :list="contactList"></contact>
      <customer ref="customer"></customer>
    </lz-dialog>
    <user ref="user"></user>
  </div>
</template>
<script>
import BigNumber from 'bignumber.js';
import cfg from './config';
import user from './user';
import contact from './contact';
import customer from './customer';

export default {
  components: {
    user,
    contact,
    customer,
  },
  data() {
    return {
      search: JSON.parse(JSON.stringify(cfg.search)),
      config: {
        url: '/agent/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (this.$route.name === 'agent-list') {
              el.htmlButton.splice(1, 0, {
                name: '查看订单',
                key: 'order',
                type: 'primary',
              });
            }
            if (el.btn_user) {
              el.htmlButton.push({
                name: '查看账号',
                key: 'user',
                type: 'primary',
              });
            }
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        {
          name: '名称',
          prop: 'name',
        },
        { name: '行业', prop: 'industry' },
        { name: '区域',
          func: (ops) => `${ops.contact_province_name} ${ops.contact_city_name} ${ops.contact_area_name}`,
        },
        { name: '订单总金额', prop: 'ticket_amount' },
        { name: '预付金额', prop: 'total_amount' },
        {
          name: '余额',
          prop: 'balance',
          func: (ops) => {
            let str = ops.balance;
            const total = BigNumber(ops.balance / ops.ticket_amount).times(100).toFixed(1);
            if (Number(ops.balance) && Number(ops.ticket_amount)) {
              str += `<span class="stop_num">${total}%</span>`;
            }
            return str;
          },
        },
        {
          name: '状态',
          func: (ops) => {
            // const total = BigNumber(ops.balance / ops.total_amount).times(100).toFixed(0);
            const total = BigNumber(ops.balance / ops.ticket_amount).times(100).toFixed(1);
            // const isEnd = total < ops.stop_num;
            if (Number(ops.balance) && Number(ops.ticket_amount)) {
              // if (ops.warning.length > 0 && (total > ops.stop_num) && (total < ops.warning[0])) {
              //   return '<span class="stop_num orange">请充值</span>';
              // }
              if (total >= ops.stop_num) {
                let isLow = false;
                if (ops.warning) {
                  const warning = ops.warning.replace(/"|\[|\]/g, '').split(',');
                  for (let i = 0; i < warning.length; i += 1) {
                    console.log(warning[i], total);
                    if (warning[i] > total) {
                      isLow = true;
                      break;
                    }
                  }
                }
                if (isLow) return '<span class="stop_num orange">请充值</span>';
                return '<span class="stop_num">正常</span>';
              }
              if (total < ops.stop_num) {
                return '<span class="stop_num red">暂停兑换</span>';
              }
            }
            return '';
          },
        },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: cfg.form,
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
      contactList: [],
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/agent/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'user':
          this.$refs.user.init(element.id);
          break;
        case 'order':
          this.$router.push({
            name: 'order-list',
            query: {
              agent_id: String(element.id),
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.contact = this.contactList;
          params.contact.forEach((el) => {
            delete el.htmlButton; // eslint-disable-line
          });
          if (params.warning) params.warning = params.warning.split(',');
          const response = await this.$baseHttp.post('/agent/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        const params = {};
        if (response) {
          params.url = `/agent/detail?id=${response.id}`;
        } else {
          params.data = {};
        }
        const data = await this.$refs.lzForm.setValue(params);
        const bgId = this.form.find((r) => r.fieldName === 'bg_id');
        this.$set(bgId, 'value', data.bg_id === 0 ? [] : [data.bg]);
        this.form.forEach((element) => {
          if (['app_key', 'app_secret'].includes(element.fieldName)) {
            if (data?.id) this.$set(element, 'value', data?.app[element.fieldName] ?? '');
            this.$set(element, 'hide', !data.is_api);
          }
        });
        this.contactList = data ? data.contact : [];
        this.$refs.customer.init(response ? response.id : -1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .stop_num {
  background: #009688;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 12px;
  transform: scale(.9);
  &.red {
    background: #BF311F;
  }
  &.orange {
    background: orange;
  }
  &.small {
    transform: scale(.8);
  }
}
</style>
